import '../styles/Profile.css';
import Contact from './Contact';
import Resume from "../assets/pdfs/Resume_JustinWong.pdf";

const Profile = () => {
  return (
    <section className="profile">
      <a href={Resume} type="application/pdf" target="_blank" rel="noopener noreferrer">
        <span className="tag job-seeking">
          Currently Job Seeking
        </span>
      </a>
      <section className="profile-text-container">
        <p className="profile-text">Welcome! I am</p>
        <p className="profile-text">💻 A Software Engineer based out of Vancouver, BC.</p>
        <p className="profile-text">🎓 A CS graduate from The University of British Columbia (BSc).</p>
        <p className="profile-text">🌎 Always on the look out to make a positive impact through technology!</p>
      </section>
      <Contact />
    </section>
  );
}

export default Profile;