import '../styles/Contact.css';

const Contact = () => {
    return (
        <ul className="contact-nav">
            <li>
                <a href="mailto:hello@justincswong.me" target="_blank" rel="noopener noreferrer">
                    <i className="contact-icon fa fa-envelope" />
                    <span>hello@justincswong.me</span>
                </a>
            </li>
            <li>
                <a href="//linkedin.com/in/justincswong/" target="_blank" rel="noopener noreferrer">
                    <i className="contact-icon fa fa-linkedin" />
                    <span>Connect with me</span>
                </a>
            </li>
            <li>
                <a href="//github.com/justincswong" target="_blank" rel="noopener noreferrer">
                    <i className="contact-icon fa fa-github"/>
                    <span>Check out my GitHub</span>
                </a>
            </li>
        </ul>
    );
}

export default Contact;