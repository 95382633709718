import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Projects from './components/Projects';

const App = () => {
  return (
    <section>
      <Header />
      <main>
        <Hero />
        <Projects />
      </main>
      <Footer />
    </section>
  );
}

export default App;
