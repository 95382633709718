import "../styles/Projects.css";
import ProjectItem from "./ProjectItem";
import { featuredProject, projects } from "../assets/data/projects";
import useViewport from '../utils/useViewport.js';

const Projects = () => {
    const { width } = useViewport();
    const firstBreakpoint = 1400;
    const secondBreakpoint = 1000;
    const featuredProjectItem = <ProjectItem src={featuredProject.img} href={featuredProject.href} title={featuredProject.title} description={featuredProject.desc} stack={featuredProject.stack} />;
    const projectItems = projects.map(({img, href, title, desc, stack}) => <ProjectItem src={img} href={href}title={title} description={desc} stack={stack} />);
    const gridFix = projects.length % 2 === 0;
    const gridFixItem = <ProjectItem src={"Avatar_JustinWong.jpg"} title={""} description={""} stack={""} hidden />;

    return (
        <section>
            <h3 className="project-heading">Projects</h3>
            <section id="projects" className="project-grid" >
                    {width > firstBreakpoint ? projectItems : [featuredProjectItem, ...projectItems]}
                    {width > firstBreakpoint ?  gridFix ? null : width < secondBreakpoint ? null : gridFixItem : !gridFix ? null : width < secondBreakpoint ? null : gridFixItem}
            </section>
        </section>
    );
}

export default Projects;