import '../styles/Hero.css';
import Profile from './Profile';
import FeaturedProject from './FeaturedProject';
import { featuredProject } from "../assets/data/projects";
import useViewport from '../utils/useViewport.js';

const Hero = () => {
    const { width } = useViewport();
    const breakpoint = 1400;

    return (
        <section className="hero">
            <Profile />
            {width > breakpoint ?
                <section className="featured-project">
                    <h3 className="featured-project-heading">Featured Project</h3>
                    <FeaturedProject src={featuredProject.img} href={featuredProject.href} title={featuredProject.title} description={featuredProject.desc} stack={featuredProject.stack} />
                </section> : null}
        </section>
    );
}

export default Hero;