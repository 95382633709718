import '../styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <span>&copy; 2022 Justin Wong.</span>
            <nav className="footer-nav">
                <ul>
                    <li className="footer-nav-item">
                        <a href="mailto:hello@justincswong.me" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-envelope" />
                        </a>
                    </li>
                    <li className="footer-nav-item">
                        <a href="//linkedin.com/in/justincswong/" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-linkedin" />
                        </a>
                    </li>
                    <li className="footer-nav-item">
                        <a href="//github.com/justincswong/" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-github" />
                        </a>
                    </li>
                </ul>
            </nav>
        </footer >
    );
}

export default Footer;