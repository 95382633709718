import '../styles/ProjectItem.css'

const ProjectItem = ({ src, href, title, description, stack, hidden }) => {
    return (
        <section className={`card project-card${hidden ? " hidden" : ""}`}>
            <a href={href} target="_blank" rel="noopener noreferrer">
                <img className="project-image" src={require(`../assets/images/${src}`)} alt={`Screenshot of ${title}`} />
            </a>
            <span className="project-stack">{stack}</span>
            <h4 className="project-title">{title}</h4>
            <p>{description}</p>
        </section>
    );
}

export default ProjectItem;