import '../styles/Header.css';
import Avatar from "../assets/images/Avatar_JustinWong.jpg";
import Resume from "../assets/pdfs/Resume_JustinWong.pdf";

const Header = () => {
    return (
        <header className="header">
            <a className="image-link" href="/" >
                <img className="avatar" src={Avatar} alt="Justin Wong" />
                <section className="name-title">
                    <h1 className="name">Justin Wong</h1>
                    <h2 className="title">Software Engineer</h2>
                </section>
            </a>
            <nav>
                <ul>
                    <li className="header-item">
                        <a href="/#projects" >Projects</a>
                    </li>
                    <li className="header-item">
                        <a href={Resume} type="application/pdf" target="_blank" rel="noopener noreferrer">Resume</a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;