import '../styles/FeaturedProject.css'

const FeaturedProject = ({ src, href, title, description, stack }) => {
    return (
        <section className="card featured-project-card">
            <a href={href} target="_blank" rel="noopener noreferrer">
                <img className="featured-project-image" src={require(`../assets/images/${src}`)} alt={`Screenshot of ${title}`} />
            </a>
            <span className="featured-project-stack">{stack}</span>
            <h4 className="featured-project-title">{title}</h4>
            <p>{description}</p>
        </section>
    );
}

export default FeaturedProject;